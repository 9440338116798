import Swiper, { Navigation } from 'swiper';

const slider = '.js-area-carousel';

const areaCarousel = new Swiper(slider, {
    modules: [Navigation],
    navigation: {
        prevEl: '.areas__arrow-left',
        nextEl: '.areas__arrow-right',
    },
    breakpoints: {
        1200: {
            slidesPerView: 3,
            spaceBetween: 32,
        },
        768: {
            slidesPerView: 2.5,
            spaceBetween: 16,
        },
        400: {
            slidesPerView: 1.5,
            spaceBetween: 16,
        },
        360: {
            slidesPerView: 1.2,
            spaceBetween: 16,
        },
        300: {
            slidesPerView: 1,
            spaceBetween: 16,
        },
    },
});
